<template>
  <b-card-code title="Simple List Group">
    <b-card-text>
      List groups are a flexible and powerful component to display a list of content.
    </b-card-text>

    <b-list-group>
      <b-list-group-item>Cras justo odio</b-list-group-item>
      <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>
      <b-list-group-item>Morbi leo risus</b-list-group-item>
      <b-list-group-item>Porta ac consectetur ac</b-list-group-item>
      <b-list-group-item>Vestibulum at eros</b-list-group-item>
    </b-list-group>

    <template #code>
      {{ codeListGroupBasic }}
    </template>
  </b-card-code>
</template>

<script>
import { BCardText, BListGroup, BListGroupItem } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import { codeListGroupBasic } from './code'

export default {
  components: {
    BCardCode,
    BListGroup,
    BListGroupItem,
    BCardText,
  },
  data() {
    return { codeListGroupBasic }
  },
}
</script>
